<script>
export default {
  metaInfo: {
    title: 'Экономия на здоровье: умные способы от ХаниМани',
    meta: [
      { name: 'description', content: 'Полезная и безопасная экономия на здоровье для Умных Пчёл. Действенные способы снизить расходы на врачей и лекарства в Энциклопедии Экономии ХаниМани' },
      { name: 'keywords', content: 'экономия на здоровье лекарствах врачах' },
      { name: 'human_title', content: 'Здоровье, врачи, лекарства, болезни и профилактика' },
      { name: 'human_subtitle', content: 'Как сделать так, чтобы ни вам, ни кошельку было не больно' }
    ]
  }
}
</script>

<template lang="pug">
div
  img.il_frame.is-pulled-right(width='150', alt='Экономия на здоровье: умные способы от ХаниМани', title='Экономия на здоровье: умные способы от ХаниМани', src='https://hm2-forum.s3-eu-west-1.amazonaws.com/optimized/1X/f0b7afaa2bd7e4c26a6c2474c41e7055924152f4_1_365x500.png')
  p Умным Пчёлам не нравится болеть.
  p
    | Во-первых, это скучно и грустно. Во-вторых — достаточно дорого. Болезнь выбивает из колеи и нас самих, и наш личный бюджет. С такими ценами на лекарства и услуги врачей в этом нет ничего удивительного.
  p
    | Переплачивать всегда неприятно, пусть даже речь идёт о лечении. Ответственно заявляем: экономия на здоровье может быть оправданной и безопасной! Мы в ХаниМани, например, знаем много полезных способов, как сократить расходы на медицину и сделать процесс выздоровления менее заметным для кошелька. И сегодня они собраны для Вас в нашей Энциклопедии Экономии.
  p Вооруж-ж-жайтесь! И не болейте!
  h2 Самое важное — старайтесь не болеть
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_1.ee_advice_index(href='#advice_1') 1
            a.fa-lg(data-href='/advices/c30958def841f1ddb6a42d5bc7ef727c3a4de20d/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 9
          td
            p
              | Ограничьте себя в алкоголе. Доказано: 30 грамм спиртного в день гарантируют цирроз печени мужчинам, 10 грамм — женщинам.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_2.ee_advice_index(href='#advice_2') 2
            a.fa-lg(data-href='/advices/43439480b53b2e56be2e622ef1e2a942eca7c109/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 12
          td
            p
              | Не курите. Курение снижает иммунитет, из-за чего даже лёгкая простуда переносится сложнее и требует визита ко врачу.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_3.ee_advice_index(href='#advice_3') 3
            a.fa-lg(data-href='/advices/ab1da709f93e2d9ac9ae6a2e11780d281c8475bb/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 9
          td
            p
              | Откажитесь от фастфуда. Домашняя еда вкуснее, дешевле и не вызывает проблем со здоровьем.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_4.ee_advice_index(href='#advice_4') 4
            a.fa-lg(data-href='/advices/89a037473ac087665445539241664318843a9f80/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Соблюдайте привычный режим дня: любое нарушение привычного ритма может спровоцировать сбой в работе иммунной системы.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_5.ee_advice_index(href='#advice_5') 5
            a.fa-lg(data-href='/advices/b53fda6b36057a81cce15974d2e046c458136398/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 11
          td
            p
              | Не пренебрегайте сном, гимнастикой и правильным питанием. Это бесплатные и долгосрочные инвестиции в здоровье.
  h2 Профилактика
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_6.ee_advice_index(href='#advice_6') 6
            a.fa-lg(data-href='/advices/7b62f601b5094cd843bba3cc6e7b0e6f4cface65/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p Заранее планируйте профилактические осмотры в бесплатных поликлиниках.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_7.ee_advice_index(href='#advice_7') 7
            a.fa-lg(data-href='/advices/b6f501451b0ae11ee2c821dffed600b4cf9216bc/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Делайте прививки от гриппа — так вы повысите свои шансы остаться здоровым во время эпидемии.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_8.ee_advice_index(href='#advice_8') 8
            a.fa-lg(data-href='/advices/72216b77bc27eaffb37ada53beb321523a92c013/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 5
          td
            p
              | Делайте профессиональную чистку зубов дважды в год: это отличная профилактика не только кариеса, но и дорогостоящего лечения зубов.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_9.ee_advice_index(href='#advice_9') 9
            a.fa-lg(data-href='/advices/e44f3a7860770255a08fc2e506595e31a7745c61/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 5
          td
            p
              | Заведите отдельный сберегательный счёт на случай болезни. Так болезнь не застанет Вас врасплох.
  h2 Лекарства
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_10.ee_advice_index(href='#advice_10') 10
            a.fa-lg(data-href='/advices/6f29670b54f08313481f31145429374735b8bcb7/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Беременные женщины и дети до 3-х лет могут бесплатно получать витамины и лекарства из перечня необходимых.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_11.ee_advice_index(href='#advice_11') 11
            a.fa-lg(data-href='/advices/1a7179011fdfa487e7877a75ac98517677fe28b5/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 3
          td
            p
              | Спрашивайте в аптеках аналоги нужного лекарства — при одинаковом составе препараты зарубежного производства стоят дороже.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_12.ee_advice_index(href='#advice_12') 12
            a.fa-lg(data-href='/advices/7bda9c8c096086c6323afd7c95b486a933717c08/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 2
          td
            p Сравнивайте цены на лекарства в разных аптеках, ищите скидки и акции
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_13.ee_advice_index(href='#advice_13') 13
            a.fa-lg(data-href='/advices/9f1709283fac6b901ca635d56c4d224d7728e394/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Запивайте таблетки только водой. Взаимодействие с кофе, газировкой и, тем более, алкоголем может вызвать осложнения и  потребовать дополнительного лечения.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_14.ee_advice_index(href='#advice_14') 14
            a.fa-lg(data-href='/advices/5e7efba35fd2fa68ae42405f135c0232d7d44a77/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count')
          td
            p
              | Не стесняйтесь просить у знакомых накопительные и бонусные карты для аптек и частных клиник.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_15.ee_advice_index(href='#advice_15') 15
            a.fa-lg(data-href='/advices/e9ff10bcd8c14db828238536c031078603eb9fdf/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count')
          td
            p
              | Сомневаетесь в эффективности прописанных таблеток? Купите самую маленькую упаковку — если лекарство поможет, его всегда можно докупить.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_16.ee_advice_index(href='#advice_16') 16
            a.fa-lg(data-href='/advices/c420f7ed1f9470984fcd8cb5b71943d746474bd2/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count')
          td
            p
              | Прежде чем бежать в аптеку, узнайте, не завалялось ли нужное лекарство у родных или друзей.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_17.ee_advice_index(href='#advice_17') 17
            a.fa-lg(data-href='/advices/8509a07f802d73d17c98632a62dfb8b6a5ccc0c3/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Покупайте лекарства строго в том количестве и дозировке, которое нужно для курса лечения. Не соглашайтесь брать то, что осталось в ближайшей аптеке.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_18.ee_advice_index(href='#advice_18') 18
            a.fa-lg(data-href='/advices/c024dfbcceb69249cee316074dfd0aaf18a88142/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count')
          td
            p Старайтесь покупать лекарства в рабочее время. Ночные аптеки — самые дорогие.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_19.ee_advice_index(href='#advice_19') 19
            a.fa-lg(data-href='/advices/662c13c444a86f280d5d63a43e38cc1b4037e95d/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Не выбрасывайте медикаменты  после выздоровления до истечения срока годности — они ещё могут пригодиться.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_20.ee_advice_index(href='#advice_20') 20
            a.fa-lg(data-href='/advices/62888fc8ed41baccc4d5636e4fc5520fd548ca3c/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 5
          td
            p
              | Следите, чтобы в домашней аптечке всегда были средства первой необходимости, чтобы в экстренной ситуации не пришлось ехать за ними в круглосуточную аптеку на такси.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_21.ee_advice_index(href='#advice_21') 21
            a.fa-lg(data-href='/advices/529c690515b02f9a282c390d7c7b1d25ac9ad795/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Не ленитесь пользоваться народными средствами при первых симптомах заболевания. Лимон, мёд, ингаляции с ромашкой могут быстро и бесплатно предотвратить болезнь.
  h2 Больницы
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_22.ee_advice_index(href='#advice_22') 22
            a.fa-lg(data-href='/advices/2b89a01d66a5317ce2c5a9ca68c5c6ba853cee78/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Если среди ваших знакомых есть врачи, они могут проконсультировать Вас или высказать альтернативное мнение. Совет человека, которому вы доверяете, сэкономит немало денег.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_23.ee_advice_index(href='#advice_23') 23
            a.fa-lg(data-href='/advices/1b7f2dacf311d4a0636189fb2487fbc13bcd1dbc/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 2
          td
            p
              | Обращайтесь ко врачу как можно раньше. На ранней стадии болезнь вылечить и проще, и дешевле.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_24.ee_advice_index(href='#advice_24') 24
            a.fa-lg(data-href='/advices/1329a1415dd75245b6513bc9042c5fdc2c0e78b9/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Вы не обязаны проходить все назначенные обследования в одной клинике. Делайте их там, где дешевле.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_25.ee_advice_index(href='#advice_25') 25
            a.fa-lg(data-href='/advices/32c2f74ac3724a2e8ae50f4a938db1c47884d0ca/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 1
          td
            p
              | Посещайте платные клиники, выбрав для себя самую выгодную программу добровольного медицинского страхования. Это дешевле, чем каждый раз платить за приём.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_26.ee_advice_index(href='#advice_26') 26
            a.fa-lg(data-href='/advices/c1f5f6246bf6ad0189f98ca15a43d8e764fbb0da/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Всегда оформляйте больничный лист, чтобы получить зарплату за пропущенные дни. Компенсация составляет от 60 до 100% и зависит от стажа.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_27.ee_advice_index(href='#advice_27') 27
            a.fa-lg(data-href='/advices/0134e36551bc77aad7e2aa2c327df46ef1db7b13/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 2
          td
            p
              | Если в платной клинике Вам назначают подозрительно много анализов и обследований, обратитесь к другому специалисту.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_28.ee_advice_index(href='#advice_28') 28
            a.fa-lg(data-href='/advices/c33ef266a5a38eacaad27eae71b06b8e04d8b10e/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count')
          td
            p
              | Попробуйте медицинский туризм — возможно, в соседнем городе нужная Вам услуга стоит намного дешевле?
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_29.ee_advice_index(href='#advice_29') 29
            a.fa-lg(data-href='/advices/4f7adbf22212a15cbfe9e4119e69b2ff99abd8bc/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p С направлением платного врача сдать анализы можно и в бесплатной поликлинике.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_30.ee_advice_index(href='#advice_30') 30
            a.fa-lg(data-href='/advices/cf1fea5d0022982344f7412843d4e6a01e859cde/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 4
          td
            p
              | Доводите курс лечения до конца. Прерванное лечение может привести к рецидиву и ещё большей трате денег.
  h2 Разное
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_31.ee_advice_index(href='#advice_31') 31
            a.fa-lg(data-href='/advices/6aad98d8d82c9f7f5308c637c45d2b7e18802a9a/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 3
          td
            p Лечились платно? Имеете право вернуть 13% от затрат через налоговые органы.
  .ee_advice.box(style='padding: 10px;')
    table
      tbody
        tr
          td
            a#advice_32.ee_advice_index(href='#advice_32') 32
            a.fa-lg(data-href='/advices/d946ac3eaab347dba5c7a42a7432bdad9b350054/like', role='ee_like_advice_link')
              .icon.is-large
                v-icon(name='thumbs-up')
              span(role='ee_advice_likes_count') 2
          td
            p
              | Если заболел ребенок, супруг или родители, не берите отгулы, а оформляйте больничный лист по уходу за заболевшим.

</template>
